<template>
  <div class="flex flex-col md:flex-row justify-center items-center font-roboto-normal text-xs">
    <span class="leading-5 text-xs">© {{ year }} {{ $t('footer.allRightsReserved') }} </span>
    <div v-if="includeAdditionalInfo" class="flex flex-col lg:flex-row">
      <div class="flex items-center">
        <span class="mx-2">&#183;</span>
        <NuxtLink target="_blank" to="/terms-of-use">{{ $t('footer.termsConditions') }}</NuxtLink>
      </div>
      <div class="flex items-center">
        <span class="mx-2">&#183;</span>
        <NuxtLink target="_blank" to="/privacy-notice">{{ $t('footer.privacyPolicy') }}</NuxtLink>
      </div>
      <div class="flex items-center">
        <span class="mx-2">&#183;</span>
        <NuxtLink target="_blank" to="/eula">{{ $t('footer.eula') }}</NuxtLink>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
interface ComponentProps {
  includeAdditionalInfo?: boolean
}

defineProps<ComponentProps>()

const year = new Date().getFullYear()
</script>
