<template>
  <div class="flex justify-center lg:justify-end">
    <div class="flex items-center w-fit">
      <NuxtLink target="_blank" to="http://twitter.com/cardanospot">
        <IconX :class="getIconFill()" class="mr-5" />
      </NuxtLink>
      <NuxtLink target="_blank" to="https://www.facebook.com/CardanoSpot/">
        <IconFacebook :class="getIconFill()" class="mr-5" />
      </NuxtLink>
      <NuxtLink target="_blank" to="https://www.youtube.com/channel/UC7llllpJtfVJuHiDcO_s5Dw">
        <IconYoutube :class="getIconFill()" class="mr-5" />
      </NuxtLink>
      <NuxtLink target="_blank" to="https://t.me/cardanospot">
        <IconTelegram :class="getIconStroke()" class="mr-5 w-[30px] h-[30px]" />
      </NuxtLink>
      <NuxtLink target="_blank" to="https://www.reddit.com/user/CardanoSpot/">
        <IconReddit :class="getIconFill()" class="w-[30px] h-[30px]" />
      </NuxtLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import IconX from 'assets/icons/logo-x.svg'
import IconFacebook from 'assets/icons/logo-facebook.svg'
import IconYoutube from 'assets/icons/logo-youtube.svg'
import IconTelegram from 'assets/icons/logo-telegram.svg'
import IconReddit from 'assets/icons/logo-reddit.svg'

interface ComponentProps {
  variant?: 'white' | 'blue'
}

const props = withDefaults(defineProps<ComponentProps>(), {
  variant: 'blue'
})
const getIconStroke = () => {
  return props.variant === 'blue' ? '[&>path]:stroke-cs-light-blue [&>g>path]:stroke-cs-light-blue' : ''
}

const getIconFill = () => {
  return props.variant === 'blue' ? '[&>*]:fill-cs-light-blue' : ''
}
</script>
