<template>
  <div
    id="contact-us"
    class="bg-cs-dark-background py-5 px-5 lg:py-[50px] lg:px-[50px] grid [grid-template-areas:'footerLogo''footerSeparator1''footerSocialLinks''footerLinksList''xmasImage''footerSeparator2''footerMessage'] md:[grid-template-areas:'footerLogo_footerSocialLinks''footerSeparator1_footerSeparator1''footerLinksList_footerLinksList''xmasImage_xmasImage''footerSeparator2_footerSeparator2''footerMessage_footerMessage']"
  >
    <CardanoSpotLogoLight />
    <Separator class="my-4 mb-4 lg:my-[50px] [grid-area:footerSeparator1] !bg-cs-black" />
    <FooterSocialLinks class="[grid-area:footerSocialLinks]" />
    <FooterLinkList :class="isXmas ? '' : 'mb-20'" class="[grid-area:footerLinksList]" />
    <div class="[grid-area:xmasImage] -mx-5 lg:-mx-[50px]">
      <NuxtImg v-if="isXmas" class="w-full" format="webp" loading="lazy" quality="100" src="/footer-xmas.png" />
    </div>
    <Separator :class="isXmas ? '!mt-0' : ''" class="mb-4 lg:my-[50px] [grid-area:footerSeparator2] !bg-cs-black" />
    <FooterMessage class="[grid-area:footerMessage] text-cs-light-blue" />
  </div>
</template>

<script lang="ts" setup>
import {useIsXmasMode} from '~/composables/common'

const isXmas = useIsXmasMode()
</script>
